<template>
    <div class="home_section4_container">
        <h1 class="home_section4_title wow animate__animated animate__aplayzFadeInUp animate__fast" data-wow-offset="30">
            <slot name="homesectiont4title">
                어플레이즈 상품
            </slot>
        </h1>
        <h2 class="home_section4_subtitle wow animate__animated animate__aplayzFadeInUp animate__fast" data-wow-offset="30">
            <slot name="homesecton4subtitle">
                상업 공간, 이동 공간, 복합 공간을 비롯한
                <div class="home_section4_subtitle_br"></div>
                다양한 공간별 상품을 제공하고 있습니다.
            </slot>
        </h2>
        <ul
            class="home_section4_list dp_flex flex_column align_center wow animate__animated animate__aplayzFadeInUp animate__fast"
            data-wow-offset="40"
        >
            <li
                class="home_section4_list_item "
                :class="{ active1: listItem[0].isActive, en: isen }"
                id="homeCardItem1"
                data-index="0"
                @mouseover="onMouseOver($event, 'productDim1')"
                @mouseleave="onMouseLeave($event, 'productDim1')"
                @click="onClickActive(0, 'productDim1')"
            >
                <ul class="list_item_contents item1 flex_space_between h_100per">
                    <li>
                        <h1 class="contents_title">
                            <slot name="contentstitle1">
                                상업 공간
                            </slot>
                        </h1>
                        <p class="content_paragraph">
                            <slot name="contentparagraph1">
                                AI 기반 매장별 최적 음악을 실시간으로 자동 선곡하고 재생하여,<br />
                                매장 사업자의 선곡 및 저작권 관련 부담을 해소해 드립니다.
                            </slot>
                        </p>
                        <p class="content_paragraph_mo">
                            <slot name="contentparagraph1mo">
                                AI 기반 매장별 최적 음악을 실시간으로 <br />
                                자동 선곡하고 재생하여,매장 사업자의 <br />
                                선곡 및 저작권 관련 부담을 해소해 드립니다.
                            </slot>
                        </p>
                    </li>
                    <li class="align_center add_btn_wrap">
                        <img
                            class="add_btn cursor"
                            :class="{ close: listItem[0].isActive }"
                            src="/media/images/home/add_btn.png"
                            alt="add_btn"
                        />
                    </li>
                </ul>
                <!-- Card1 -->
                <ul class="list_item_contents2" v-if="listItem[0].isActive">
                    <li class="w_100per list_item_contents2_card1">
                        <slot name="contents2card1img1">
                            <img
                                class="list_item_contents2_card1_img1"
                                src="/media/images/home/sectiont4_card1_img1.png"
                                alt="sectiont4_card1_img1.png"
                            />
                        </slot>

                        <ul class="list_item_contents2_card1_list">
                            <li class="list_item_contents2_card1_listitem">
                                <figure class="list_item_contents2_card1_icon_wrap">
                                    <img
                                        class="list_item_contents2_card1_icon"
                                        src="/media/images/home/sectiont4_card1_icon1.png"
                                        alt="icon1"
                                    />
                                </figure>

                                <ul class="flex_column list_item_contents2_card1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card1texttitle1">
                                                테마 채널
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card1textdesc1">
                                                피드백 반영으로 매시간 자동 업데이트
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li class="list_item_contents2_card1_listitem">
                                <figure class="list_item_contents2_card1_icon_wrap">
                                    <img
                                        class="list_item_contents2_card1_icon onoff"
                                        src="/media/images/home/sectiont4_card1_icon2.png"
                                        alt="icon1"
                                    />
                                </figure>

                                <ul class="flex_column list_item_contents2_card1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card1texttitle2">
                                                자동 on/off 기능
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card1textdesc2">
                                                영업 시간, 브레이크 타임에 따른 플레이어 on/off
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>

                            <li class="list_item_contents2_card1_listitem">
                                <figure class="list_item_contents2_card1_icon_wrap">
                                    <img
                                        class="list_item_contents2_card1_icon like"
                                        src="/media/images/home/sectiont4_card1_icon3.png"
                                        alt="icon1"
                                    />
                                </figure>

                                <ul class="flex_column list_item_contents2_card1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card1texttitle3">
                                                피드백 반영
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card1textdesc3">
                                                좋아요, 싫어요를 누를수록 더욱 똑똑해지는 AI
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>

                            <li class="list_item_contents2_card1_listitem">
                                <figure class="list_item_contents2_card1_icon_wrap">
                                    <img
                                        class="list_item_contents2_card1_icon time"
                                        src="/media/images/home/sectiont4_card1_icon4.png"
                                        alt="icon1"
                                    />
                                </figure>

                                <ul class="flex_column list_item_contents2_card1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card1texttitle4">
                                                시간대별 큐레이션 설정
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card1textdesc4">
                                                시간/요일별 공간 특성에 따른 음악 큐레이션 설정
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="w_100per product1_sub2_wrap">
                        <h2 class="list_item_contents2_card2_title">
                            <slot name="contents2card2title">
                                프랜차이즈 관리도 어렵지 않아요!
                            </slot>
                        </h2>
                        <ul class="list_item_contents2_card1_sub1wrap">
                            <li class="flex_column">
                                <slot name="contents2card1sub1img1">
                                    <img
                                        class="list_item_contents2_card1_sub1img1"
                                        src="/media/images/home/section4_card1_img2.png"
                                        alt="section4_card1_img2"
                                    />
                                </slot>

                                <ul class="card1_sub1_wrap flex_column">
                                    <li>
                                        <h2 class="card1_sub1_title">
                                            <slot name="card1sub1title1">
                                                다수 브랜드, 가맹점 관리 모니터링
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="card1_sub1_desc">
                                            <slot name="card1sub1desc1">
                                                복수 공간 운영 및 모니터링 솔루션을 활용하여 <br />
                                                본사 브랜드의 가맹점 음악 재생/큐레이션 설정 현황 등을 확인할 수 있습니다.
                                            </slot>
                                        </p>
                                        <p class="card1_sub1_desc_tablet">
                                            <slot name="card1subdesc1tablet">
                                                복수 공간 운영 및 모니터링 솔루션을 활용하여 <br />
                                                본사 브랜드의 가맹점 음악 재생/큐레이션 설정 현황 등을 <br />
                                                확인할 수 있습니다.
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li class="flex_column list_item_contents2_card1_sub2">
                                <slot name="contents2card1sub1img2">
                                    <img
                                        class="list_item_contents2_card1_sub1img1"
                                        src="/media/images/home/section4_card1_img3.png"
                                        alt="section4_card1_img2"
                                    />
                                </slot>

                                <ul class="card1_sub1_wrap flex_column">
                                    <li>
                                        <h2 class="card1_sub1_title">
                                            <slot name="card1sub1title2">
                                                안내방송
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="card1_sub1_desc">
                                            <slot name="card1sub1desc2">
                                                매장내 안내방송, 브랜드 로고송, 이벤트 홍보, 특정 상품 안내 등 오디오 콘텐츠를 <br />
                                                본사가 원하는 시간에 원하는 안내 멘트 재생이 가능합니다.
                                            </slot>
                                        </p>
                                        <p class="card1_sub1_desc_tablet">
                                            <slot name="card1sub1desc2tablet">
                                                매장내 안내방송, 브랜드 로고송, 이벤트 홍보, <br />
                                                특정 상품 안내 등 오디오 콘텐츠를 본사가 원하는 시간에<br />
                                                원하는 안내 멘트 재생이 가능합니다.
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <img class="product1_img" src="/media/images/home/product1.png" alt="product1" />
                <div id="productDim1" class="product_img_dim" :class="{ open: listItem[0].isActive }"></div>
            </li>
            <li
                class="home_section4_list_item second"
                id="homeCardItem2"
                data-index="1"
                :class="{ active2: listItem[1].isActive, en: isen }"
                @mouseover="onMouseOver($event, 'productDim2')"
                @mouseleave="onMouseLeave($event, 'productDim2')"
                @click="onClickActive(1, 'productDim2')"
            >
                <ul class="list_item_contents item2 flex_space_between h_100per">
                    <li>
                        <h1 class="contents_title">
                            <slot name="contentstitle2">
                                이동 공간
                            </slot>
                        </h1>
                        <p class="content_paragraph">
                            <slot name="contentparagraph2">
                                주행 환경에 최적화된 차량용 큐레이션 서비스를 통해 운전자 음악 취향 뿐만 아니라, <br />
                                차종, 교통상황, 목적지 등 주행 복합 데이터를 활용한 음악 큐레이션을 제공합니다.
                            </slot>
                        </p>
                        <p class="content_paragraph_mo">
                            <slot name="contentparagraph2mo">
                                주행 환경에 최적화된 차량용 큐레이션 <br />
                                서비스를 통해 운전자 음악 취향 뿐만 아니라,<br />
                                차종, 교통상황, 목적지 등 주행 복합 데이터를 <br />
                                활용한 음악 큐레이션을 제공합니다
                            </slot>
                        </p>
                    </li>
                    <li class="align_center add_btn_wrap">
                        <img
                            class="add_btn cursor"
                            :class="{ close: listItem[1].isActive }"
                            src="/media/images/home/add_btn.png"
                            alt="add_btn"
                        />
                    </li>
                </ul>
                <ul class="list_item_contents2" v-if="listItem[1].isActive">
                    <!-- Card2 -->
                    <li class="w_100per list_item_contents2_card2">
                        <slot name="contentscard2img1">
                            <img
                                class="list_item_contents2_card2_img1"
                                src="/media/images/home/section4_card2_img1.png"
                                alt="section4_card2_img1.png"
                            />
                        </slot>

                        <ul class="list_item_contents2_card2_textwrap flex_column">
                            <li class="list_item_contents2_card2_listitem">
                                <figure class="list_item_contents2_card2_icon_wrap">
                                    <img
                                        class="list_item_contents2_card2_icon"
                                        src="/media/images/home/section4_card2_icon1.png"
                                        alt="section4_card2_icon1.png"
                                    />
                                </figure>
                                <ul class="flex_column list_item_contents2_card2_subcard1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card2texttitle1">
                                                스와이프로 채널 변경
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card2textdesc1">
                                                주행중에도 손쉽게 채널 변경 가능
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li class="list_item_contents2_card2_listitem">
                                <figure class="list_item_contents2_card2_icon_wrap">
                                    <img
                                        class="list_item_contents2_card2_icon musicgenre"
                                        src="/media/images/home/section4_card2_icon2.png"
                                        alt="section4_card2_icon1.png"
                                    />
                                </figure>
                                <ul class="flex_column list_item_contents2_card2_subcard1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card2texttitle2">
                                                이용 목적에 따른 음악 추천
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card2textdesc2">
                                                출퇴근, 드라이브, 여행 등 다양한 목적에 따른 추천 재생
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li class="list_item_contents2_card2_listitem">
                                <figure class="list_item_contents2_card2_icon_wrap">
                                    <img
                                        class="list_item_contents2_card2_icon musiccast"
                                        src="/media/images/home/section4_card2_icon3.png"
                                        alt="section4_card2_icon1.png"
                                    />
                                </figure>
                                <ul class="flex_column list_item_contents2_card2_subcard1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents2card2texttitle3">
                                                편리한 차량 연동
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents2card2textdesc3">
                                                안드로이드 오토 및 카플레이로 더욱 편리하게!
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <img class="product2_img" src="/media/images/home/product2.png" alt="product2" />
                <div id="productDim2" class="product_img_dim" :class="{ open: listItem[1].isActive }"></div>
            </li>
            <li
                class="home_section4_list_item"
                id="homeCardItem3"
                :class="{ active3: listItem[2].isActive, en: isen }"
                data-index="2"
                @mouseover="onMouseOver($event, 'productDim3')"
                @mouseleave="onMouseLeave($event, 'productDim3')"
                @click="onClickActive(2, 'productDim3')"
            >
                <ul class="list_item_contents item3 flex_space_between h_100per">
                    <li>
                        <h1 class="contents_title">
                            <slot name="contentstitle3">
                                복합 공간
                            </slot>
                        </h1>
                        <p class="content_paragraph">
                            <slot name="contentparagraph3">
                                다채널 통합 음원 관리 솔루션을 통해 <br />
                                복수 공간을 하나의 디바이스로 효율적 일괄 관리/모니터링이 가능합니다.
                            </slot>
                        </p>
                        <p class="content_paragraph_mo">
                            <slot name="contentparagraph3mo">
                                다채널 통합 음원 관리 솔루션을 통해<br />
                                복수 공간을 하나의 디바이스로 <br />
                                효율적 일괄 관리/모니터링이 가능합니다.
                            </slot>
                        </p>
                    </li>
                    <li class="align_center add_btn_wrap">
                        <img
                            class="add_btn cursor"
                            :class="{ close: listItem[2].isActive }"
                            src="/media/images/home/add_btn.png"
                            alt="add_btn"
                        />
                    </li>
                </ul>
                <ul class="list_item_contents2 item3" v-if="listItem[2].isActive">
                    <!-- Card3 -->
                    <li class="w_100per list_item_contents2_card3">
                        <slot name="contents2card3img1">
                            <figure class="list_item_contents2_card3_img1_wrap">
                                <img
                                    class="list_item_contents2_card3_img1"
                                    src="/media/images/home/section4_card3_img1.png"
                                    alt="section4_card3_img1.png"
                                />
                            </figure>
                        </slot>

                        <slot name="contents2card3img1tablet">
                            <figure class="list_item_contents2_card3_img1_wrap_tablet">
                                <img
                                    class="list_item_contents2_card3_img1"
                                    src="/media/images/home/section4_card3_img1_tablet.png"
                                    alt="section4_card3_img1.png"
                                />
                            </figure>
                        </slot>

                        <ul class="list_item_contents2_card3_textwrap flex_column">
                            <li class="list_item_contents2_card3_listitem">
                                <figure class="list_item_contents2_card2_icon_wrap">
                                    <img
                                        class="list_item_contents2_card3_icon"
                                        src="/media/images/home/section4_card3_icon1.png"
                                        alt="section4_card2_icon1.png"
                                    />
                                </figure>
                                <ul class="flex_column list_item_contents2_card3_subcard1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents3card1texttitle1">
                                                공간별 음악 재생 통합 관리
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents3card1textdesc1">
                                                공간별 재생/정지, 플레이어 자동 on/off 기능
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li class="list_item_contents2_card3_listitem">
                                <figure class="list_item_contents2_card2_icon_wrap">
                                    <img
                                        class="list_item_contents2_card3_icon"
                                        src="/media/images/home/section4_card3_icon2.png"
                                        alt="section4_card2_icon1.png"
                                    />
                                </figure>
                                <ul class="flex_column list_item_contents2_card3_subcard1_textwrap">
                                    <li>
                                        <h2 class="list_item_contents2_card1_texttitle">
                                            <slot name="contents3card1texttitle2">
                                                전관 장비 연동
                                            </slot>
                                        </h2>
                                    </li>
                                    <li>
                                        <p class="list_item_contents2_card1_textdesc">
                                            <slot name="contents3card1textdesc2">
                                                기존 전관 설비 연동으로 건물 소방법 이슈 해결
                                            </slot>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>

                <img class="product3_img" src="/media/images/home/product3.png" alt="product3" />
                <div id="productDim3" class="product_img_dim" :class="{ open: listItem[2].isActive }"></div>
            </li>
        </ul>
    </div>
</template>
<script>
import { WOW } from 'wowjs';
import { isNotPc } from '@/utils/Utils';
export default {
  name: 'HomeSection4',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      listItem: [{ isActive: false }, { isActive: false }, { isActive: false }],
      wow: null,
      homeSection4Item: []
    };
  },

  mounted () {
    this.wow = new WOW({
      live: false
    });
    this.wow.init();
    this.wow.sync();

    if (isNotPc()) {
      this.homeSection4Item = document.querySelectorAll('.home_section4_list_item');
      this.homeSection4Item.forEach(item => {
        item.addEventListener('transitionend', this.transitionEndEvent);
      });
    }
  },

  methods: {
    async transitionEndEvent (event) {
      if (event) {
        const { propertyName, target } = event;
        if (propertyName === 'height' && target) {
          const { dataset } = target;
          const index = parseInt(dataset.index);
          const { isActive } = this.listItem[index];
          const eleId = `productDim${index + 1}`;
          const productEle = document.querySelector(`#${eleId}`);

          await this.$nextTick();
          if (!isActive) {
            productEle.classList.remove('hover');
            this.$forceUpdate();
          }
        }
      }
    },
    async onClickActive (index, eleId) {
      const cardList = [0, 1, 2];
      const filterCardList = cardList.filter(item => item !== index);
      filterCardList.forEach(idx => {
        this.listItem[idx].isActive = false;
      });
      const { isActive } = this.listItem[index];
      this.listItem[index].isActive = !isActive;
      if (!this.listItem[index].isActive) {
        const productEle = document.querySelector(`#${eleId}`);
        await this.$nextTick();
        productEle.classList.add('hover');
      }
    },
    onMouseOver (_, eleId) {
      const productEle = document.querySelector(`#${eleId}`);
      if (productEle && !productEle.classList.contains('hover')) {
        //
        productEle.classList.add('hover');
      }
    },
    onMouseLeave (_, eleId) {
      const productEle = document.querySelector(`#${eleId}`);
      if (productEle && productEle.classList.contains('hover')) {
        //
        productEle.classList.remove('hover');
      }
    }
  },
  destroyed () {
    this.homeSection4Item.forEach(item => {
      item.removeEventListener('transitionend', this.transitionEndEvent);
    });
  }
};
</script>
<style scoped src="@/assets/css/home/homesection4.css"></style>
